import error404Image from './Error404.jpg';
import {Container, Stack} from '@styled-system/jsx';
import {Button} from '~/components/ui/Button';
import {Link} from '@remix-run/react';

const Error404 = () => {
  return (
    <Container
      marginX="auto"
      maxWidth="4xl"
      p={0}
      paddingInline={0}
    >
      <img src={error404Image} alt="The page you are looking for cannot be found"/>
      <Stack direction={{base: 'column', lg: 'row'}}>
        <Button
          asChild
          flex={{lg: 1}}
        >
          <Link to="/promotions">
            Live Promotions, Ending Soon!
          </Link>
        </Button>
        <Button
          asChild
          flex={{lg: 1}}
        >
          <Link to="/winners">
            See Our Past Winners!
          </Link>
        </Button>
      </Stack>
    </Container>
  );
};

export default Error404;
